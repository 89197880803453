import React, { useState, useEffect } from "react";
import { SubscriptionPage } from "~/components/AccountPages";
import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { PageContainerEcomm } from "~/components/.base/containers";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

const Subscription = ({ location }) => {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    if (typeof window == "undefined") return;
    const stripePromise = loadStripe(
      "pk_live_51HuMybIv1cL4gMEDaVz0PyhnbNdeMLewKsAva6Fkvd2ZWS2ixWJzV5uDHfP2aK82AcDJyLkli1U6uAcxZTDx9ETz005URyNUkE"
      // "pk_test_51HuMybIv1cL4gMEDvaaI8rblWA7FZKHRiIQACvcrWHFQg5TAsmnLo3c6jjbY0lPhlkBCrchqSwereZypgxmThpf800S9bw4mmW"
    );
    setStripe(stripePromise);
  }, []);
  return (
    <div>
      <SEO title="Checkout" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <Elements stripe={stripe}>
        <PageContainerEcomm>
          <SubscriptionPage location={location} />
        </PageContainerEcomm>
      </Elements>
    </div>
  );
};

export default Subscription;
